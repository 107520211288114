import addDays from 'date-fns/addDays'
import {
  BusinessSchedule,
  DayOfWeek,
  ReservationLocation,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {normalizeSchedule} from '@wix/table-reservations-lib/schedule'
import {utcToZonedTime, setUtcGmtString} from '@wix/table-reservations-lib/timezone'

import {convertReservationNoticeToSpecialHourPeriod} from './reservationNotice'

export const getBusinessScheduleFromReservationLocation = ({
  configuration,
  location,
}: ReservationLocation = {}): BusinessSchedule => {
  // periods
  const configurationPeriods = configuration?.onlineReservations?.businessSchedule?.periods
  const locationPeriods = location?.businessSchedule?.periods

  // specialHourPeriod
  const configurationSpecialHourPeriod =
    configuration?.onlineReservations?.businessSchedule?.specialHourPeriod
  const locationSpecialHourPeriod = location?.businessSchedule?.specialHourPeriod

  // minimumReservationNotice
  const minimumReservationNotice = configuration?.onlineReservations?.minimumReservationNotice

  const periods = configurationPeriods?.length
    ? configurationPeriods
    : locationPeriods ?? defaultBusinessSchedule.periods

  const specialHourPeriod = configurationSpecialHourPeriod?.length
    ? configurationSpecialHourPeriod
    : locationSpecialHourPeriod ?? defaultBusinessSchedule.specialHourPeriod

  // take into account reservation notice period as a new special event when the restaurant is closed
  const reservationNoticePeriod = minimumReservationNotice
    ? convertReservationNoticeToSpecialHourPeriod(minimumReservationNotice, location?.timeZone)
    : undefined

  return {
    periods,
    specialHourPeriod: [
      ...(specialHourPeriod ? specialHourPeriod : []),
      ...(reservationNoticePeriod ? [reservationNoticePeriod] : []),
    ],
  }
}

export const getScheduleForMonth = ({
  dateFrom = new Date(),
  businessSchedule,
  timeZone,
}: {
  dateFrom?: Date
  businessSchedule: BusinessSchedule
  timeZone?: string | null
}) => {
  const lastDayOfPreviousMonth = new Date(
    Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), 0),
  ).toISOString()

  const firstDayOfNextMonth = new Date(
    Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth() + 1),
  ).toISOString()

  return getSchedule({
    dateFrom: lastDayOfPreviousMonth,
    dateTo: firstDayOfNextMonth,
    businessSchedule,
    timeZone,
  })
}

export const getScheduleForDate = ({
  date,
  businessSchedule,
  timeZone,
}: {
  date: Date
  businessSchedule: BusinessSchedule
  timeZone?: string | null
}) =>
  getSchedule({
    dateFrom: addDays(date, -1).toISOString(),
    dateTo: addDays(date, 1).toISOString(),
    businessSchedule,
    timeZone,
  })

const getSchedule = ({
  dateFrom,
  dateTo,
  businessSchedule,
  timeZone,
}: {
  dateFrom: string
  dateTo: string
  businessSchedule: BusinessSchedule
  timeZone?: string | null
}) =>
  normalizeSchedule({
    dateFrom,
    dateTo,
    schedule: businessSchedule?.periods as any,
    specialCases: businessSchedule?.specialHourPeriod?.map(({startDate, endDate, isClosed}) => ({
      start: setUtcGmtString(utcToZonedTime(startDate ?? '', timeZone)),
      end: setUtcGmtString(utcToZonedTime(endDate ?? '', timeZone)),
      isClosed: isClosed ?? false,
    })),
  })

// DO NOT REMOVE! The server can return undefined as a user's business schedule.
export const defaultBusinessSchedule: BusinessSchedule = {
  periods: [
    {
      closeDay: DayOfWeek.SUNDAY,
      closeTime: '00:00',
      openDay: DayOfWeek.SUNDAY,
      openTime: '00:00',
    },
  ],
  specialHourPeriod: [],
}
