import {SupportedWixLocales} from 'wix-design-systems-locale-utils'

import {FormLayout} from '../types/createStylesParams'

// Base time format, the parse functions in date utils are working based on this format, so dont change.
export const TIME_FORMAT = 'h:mm a'
export const DEFAULT_REGION_SETTINGS: SupportedWixLocales = 'en-US'

// Date format for titles.
export const SHORT_DATE_FORMAT = 'cccc, MMM d'

// these are widget ids in Dev Center. Be careful.
// do not be confused with pages names in the Editor
export const APP_DEF_ID = 'f9c07de2-5341-40c6-b096-8eb39de391fb'
export const PARENT_APP_DEF_ID = '9142e27e-2e30-4948-ad8f-7e128939efc4'

export const RESERVATION_ADD_ON_ID = 'd2a98ecd-19f3-48a6-97ca-3089d5e7e857'

export enum AppPage {
  Reservations = 'Reservations',
  ReservationDetails = 'Reservation Details',
  ReservationConfirmation = 'Reservation Confirmation',
}

export const APP_PAGE_TPA_ID: Record<AppPage, string> = {
  [AppPage.Reservations]: 'e5817b9a-d9cf-43cc-95c1-590765315440',
  [AppPage.ReservationDetails]: 'b909cac7-54ab-4b31-8b35-6fd5a52e6ebd',
  [AppPage.ReservationConfirmation]: '6a32f4a9-81c6-45e1-b99c-a3b0c4088ee2',
}

export const APP_PAGE_TO_TITLE_KEY: Record<AppPage, string> = {
  [AppPage.Reservations]: 'editor-reservations.pages.reservation.name',
  [AppPage.ReservationDetails]: 'editor-reservations.pages.reservation-details.name',
  [AppPage.ReservationConfirmation]: 'editor-reservations.pages.reservation-confirmation.name',
}

export const HELP_ARTICLE_IDS = {
  reservationAddonGfpp: '57cc795e-01b3-440e-87b9-ed6ca3207146',
  reservationAddonAllTabs: 'e48a04b4-3e39-4f7e-8952-62eb75c8a7c8',
  reservationsGfpp: 'b008e48c-6afd-4197-8908-2055fd6dfc56',
  reservationsAllTabs: 'a18bea24-a19a-4bec-9bae-decb041bd370',
  reservationDetailsGfpp: '9dc2a0cc-f654-4b5a-998d-16d456053f6e',
  reservationDetailsDesignTab: 'adc1f337-0d86-4d47-9564-fdc1bcc7b7a2',
  reservationConfirmationGfpp: 'e2ed932c-8818-4659-8089-af43a143946a',
  reservationConfirmationDesignTab: '4ed69546-8edf-4b42-8607-e9a28812242f',
  manageTab: 'ba7fa76f-8661-4a4b-8af3-5e62381b422d',
}

export const PETRI_SCOPE = 'viewer-apps-f9c07de2-5341-40c6-b096-8eb39de391fb'
export const RESTAURANTS_PETRI_SCOPE = 'restaurants'

export const EXPERIMENTS = {
  shouldShowEditFormCTAButton: 'specs.tableReservations.EditFormCtaButtonEditor',
  shouldShowNewTimeSlotsDesignSettings: 'specs.tableReservations.NewTimeSlotsDesignSettings',
  shouldShowSpiltReservationsTextSettings: 'specs.tableReservations.SplitReservationsTextSettings',
  isPaymentRuleFlowEnabled: 'specs.tableReservations.EnablePaymentRulesLiveSite',
  shouldEnableEcom: 'specs.tableReservations.enableEcomPlatform',
  shouldInstallMembersArea: 'specs.tableReservations.shouldInstallMembersArea',
  isRateLimiterErrorEnabled: 'specs.tableReservations.RateLimiterErrorMessage',
}

export const MIN_APP_PAGE_DESKTOP_WIDTH = 320

export const MIN_WIDGET_DESKTOP_WIDTH = 280

export const MIN_MOBILE_WIDTH = 280

export const ADD_ON_WIDTH: Record<FormLayout, number> = {
  [FormLayout.Strip]: 980,
  [FormLayout.Packed]: 600,
}

export const ADD_ON_X_OFFSET: Record<FormLayout, number> = {
  [FormLayout.Strip]: 0,
  [FormLayout.Packed]: 190,
}

export const APP_PAGES = Object.values(AppPage)

export enum SettingsEvents {
  APPROVAL_TEXT_SETTINGS = 'approvalTextEditorState',
}

export enum ApprovalTextEditorState {
  unknown,
  auto,
  manual,
}

export type SettingsEventsValues = {
  [SettingsEvents.APPROVAL_TEXT_SETTINGS]: ApprovalTextEditorState
}

export const ECOM_BASE_URL = '/ecom'

export const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd'

// https://github.com/wix-private/table-reservations-serverless/tree/master/packages/table-reservations-serverless-ecom
export const CREATE_CHECKOUT_ENDPOINT = '/_serverless/table-reservations-serverless-ecom/checkout'
